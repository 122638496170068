// ./components/AboutUs.js
import React from 'react';

const AboutUs = () => {
    return (
        <div>
            <h1>About Us</h1>
            <p>This is a group of volunteers working to save Old Glossop...</p>
        </div>
    );
};

export default AboutUs;
